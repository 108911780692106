import React from "react";
// import { Link } from "gatsby"; // preload links

import SecretLayout from "../components/secret-layout/secret-layout";
import caveArtImage from "../images/cave-art.png";

const SecretIndex = () => {
    return (
        <SecretLayout title="Secret Entrance" navIndex={0}>
            <img
                src={caveArtImage}
                width="1125"
                height="698"
                alt="Ultimate Aqua"
            />
        </SecretLayout>
    );
};

export default SecretIndex;
